import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

const BalanceOptions = ({value,handleTabChange,tabLabels,tabValues}) => {
    const [label1,label2,label3] = tabLabels? [...tabLabels] : ['1','2','3'];
    const [value1,value2,value3] = tabValues? [...tabValues] : [...tabLabels];
    return (
        <div className={'balance-options'}>
            <Tabs 
                value={value} 
                onChange={handleTabChange}
                centered
                variant='fullWidth'
                textColor='primary'
            >
                <Tab label={label1} value={value1}/>
                <Tab label={label2} value={value2}/>
                <Tab label={label3} value={value3}/>
            </Tabs>
            
        </div>
    )
}

export default BalanceOptions
