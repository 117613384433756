import React, { useEffect, useState } from "react";
import moment from "moment";
import { Line } from 'react-chartjs-2';
import { Button } from '@material-ui/core';
import PageHeader from './components/PageHeader';
import BalanceOptions from './components/BalanceOptions';
import Helper from './controller/Helper'
import ListItem from '@material-ui/core/ListItem';
import CollapseCard from './components/CollapseCard';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import Typography from "@material-ui/core/Typography";
import Card from "@material-ui/core/Card";
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Collapse } from "@material-ui/core";

//Thao test
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";

const PurposePage = ({ CLData, CLChildrenData, CFIData, flatData }) => {
    const totalData = {
        totalVNDStart: flatData.reduce((acc, curr) =>
            curr["StartBalance_ByCashflowLine"] > 0 ?
                {
                    inflow: acc.inflow + curr["StartBalance_ByCashflowLine"],
                    outflow: acc.outflow + 0
                } :
                {
                    inflow: acc.inflow + 0,
                    outflow: acc.outflow + curr["StartBalance_ByCashflowLine"]
                },
            { inflow: 0, outflow: 0 }),

        totalVNDMid: flatData.reduce((acc, curr) =>
            curr["MidBalance_ByCashflowLine"] > 0 ?
                {
                    inflow: acc.inflow + curr["MidBalance_ByCashflowLine"],
                    outflow: acc.outflow + 0
                } :
                {
                    inflow: acc.inflow + 0,
                    outflow: acc.outflow + curr["MidBalance_ByCashflowLine"]
                },
            { inflow: 0, outflow: 0 }),

        totalVNDEnd: flatData.reduce((acc, curr) =>
            curr["StartBalance_ByCashflowLine"] > 0 ?
                {
                    inflow: acc.inflow + curr["EndBalance_ByCashflowLine"],
                    outflow: acc.outflow + 0
                } :
                {
                    inflow: acc.inflow + 0,
                    outflow: acc.outflow + curr["EndBalance_ByCashflowLine"]
                },
            { inflow: 0, outflow: 0 })
    }
    const [totalPurpose, setTotalPurpose] = useState({ totalInflow: totalData.totalVNDMid.inflow, totalOutflow: totalData.totalVNDMid.outflow })
    const [dataCashInflow, setInflow] = useState([44, 55, 33, 48]);
    const [dataCashOutflow, setOutflow] = useState([10, 40, 22, 16]);
    const [activeCurrency, setActiveCurrency] = useState("VND");
    const [currentView, setViewClass] = useState("middle");

    const handleChangeChartData = (chartData) => {
        setInflow(chartData.inflow);
        setOutflow(chartData.outflow)
        setActiveCurrency(chartData.currency);
    }

    useEffect(() => {
        const flattenCLData = Object.values(CLData).flat();
        const startCLBalance = flattenCLData.reduce((acc, curr) =>
            curr["StartBalance_ByCashflowLine"] >= 0 ? { inflow: acc.inflow + curr["StartBalance_ByCashflowLine"], outflow: acc.outflow } : { outflow: acc.outflow + curr["StartBalance_ByCashflowLine"], inflow: acc.inflow }
            , { inflow: 0, outflow: 0 });
        const cloneCFIDAta = [...CFIData];
        let initialBalance = { inflow: startCLBalance.inflow, outflow: Math.abs(startCLBalance.outflow) }
        setInflow(cloneCFIDAta.map((month) => {
            initialBalance.inflow = initialBalance.inflow + month.inflow;
            return initialBalance.inflow;
        }));
        setOutflow(cloneCFIDAta.map((month) => {
            initialBalance.outflow = initialBalance.outflow + Math.abs(month.outflow);
            return initialBalance.outflow;
        }));
    }, [CFIData, CLData])

    const [value, setValue] = useState('middle');
    const handleTabChange = (evt, newValue) => {
        setValue(newValue);
        setViewClass(newValue);
        switch (newValue) {
            case 'middle':
                setTotalPurpose({ totalInflow: totalData.totalVNDMid.inflow, totalOutflow: totalData.totalVNDMid.outflow })
                break;
            case 'left':
                setTotalPurpose({ totalInflow: totalData.totalVNDStart.inflow, totalOutflow: totalData.totalVNDStart.outflow })
                break
            case 'right':
                setTotalPurpose({ totalInflow: totalData.totalVNDEnd.inflow, totalOutflow: totalData.totalVNDEnd.outflow })
                break
            default:
                setTotalPurpose({ totalInflow: totalData.totalVNDMid.inflow, totalOutflow: totalData.totalVNDMid.outflow })
        }
    }
    let chartConfig = {
        labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
        datasets: [
            {
                label: 'Inflow',
                data: dataCashInflow,
                backgroundColor:
                    [
                        'rgba(252, 3, 3, 0.7)',
                        'rgba(252, 194, 3, 0.7)',
                        'rgba(111, 252, 3, 0.7)',
                        'rgba(3, 165, 252, 0.7)'
                    ],
                hoverBackgroundColor: [
                    'rgba(252, 3, 3, 1)',
                    'rgba(252, 194, 3, 1)',
                    'rgba(111, 252, 3, 1)',
                    'rgba(3, 165, 252, 1)'
                ],
                borderColor: 'rgb(0, 163, 27, 1)',
                fill: false,
            },
            {
                label: 'Outflow',
                data: dataCashOutflow,
                backgroundColor:
                    [
                        'rgba(252, 3, 3, 0.7)',
                        'rgba(252, 194, 3, 0.7)',
                        'rgba(111, 252, 3, 0.7)',
                        'rgba(3, 165, 252, 0.7)'
                    ],
                hoverBackgroundColor: [
                    'rgba(252, 3, 3, 1)',
                    'rgba(252, 194, 3, 1)',
                    'rgba(111, 252, 3, 1)',
                    'rgba(3, 165, 252, 1)'
                ],
                borderColor: 'rgba(252, 3, 3, 1)',
                fill: false,
            },
        ]
    };
    const allData = [
        {
            currency: 'VND',
            inflow: [44, 55, 33, 48],
            outflow: [2, 11, 44, 2]
        },
        {
            currency: "USD",
            inflow: [12, 33, 22, 55],
            outflow: [3, 6, 8, 22]
        }
    ];

    const currencyList = allData.map(data =>
        <Button
            key={data.currency}
            color={activeCurrency === data.currency ? "primary" : "default"}
            onClick={() => handleChangeChartData(data)}
        >
            {data.currency}
        </Button>
    );

    const list = Object.keys(CLData).map((key) =>
        <ListItem key={key}>
            <CollapseCard
                children1={
                    <TopCard
                        CLItem={key}
                        CLData={CLData}
                        currentView={currentView}
                    />}
                children2={
                    <BotCard
                        CLItemData={CLData[key]}
                        currentView={currentView}
                        ParentKey={key}
                        CLChildrenData={CLChildrenData}
                        collapseCard={true}
                    />}
                collapse={true}
            />
        </ListItem>
    )

    return (
        <div className='page-container'>
            <PageHeader
                pageHeader='BÁO CÁO DÒNG TIỀN'
                lastUpdatedTime={moment().format("MMMM Do YYYY, h:mm A")}
            />
            {/* <div className='btn-group-container'>
                <ButtonGroup size='small'>
                    {currencyList}
                </ButtonGroup>
            </div> */}

            <Line
                data={chartConfig}
                width={2}
                height={2}
                options={
                    {
                        title: {
                            display: true,
                            text: "Biểu đồ lũy kế dòng tiền ra/vào",
                            position: 'bottom',
                            fontSize: 16
                        },
                        scales: {
                            yAxes: [{
                                ticks: {
                                    callback: function (value, index, values) {
                                        return Helper.cashShorten(value)
                                    }
                                }
                            }]
                        },
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    let dataset = data.datasets[tooltipItem.datasetIndex];
                                    let currentValue = dataset.data[tooltipItem.index];
                                    let result = Helper.cashShorten2(currentValue);
                                    let number = result.split(" ")[0]
                                    let unit = result.split(" ")[1]
                                    return `${Helper.numberWithCommas(number)} ${unit}`
                                }
                            }
                        }
                    }
                }
            />

            <div className='total-display'>
                <p>Tổng ra/vào {value==='middle'? 'phát sinh' : value ==='left'? 'đầu kỳ': 'lũy kế'}</p>
                <p className='green'>{Helper.cashShorten(totalPurpose.totalInflow)} VND</p>
                <p className='red'>{Helper.cashShorten(totalPurpose.totalOutflow)} VND</p>
            </div>
            <Typography className='page-annotation' variant='caption'>VND*: số tiền VND chuyển đổi từ ngoại tệ</Typography>
            <BalanceOptions
                value={value}
                handleTabChange={handleTabChange}
                tabLabels={['Đầu kỳ', 'Phát sinh', 'Lũy kế']}
                tabValues={['left', 'middle', 'right']}
            />
            <List>
                {list}
            </List>

        </div>
    );
}

const NumberDisplay = ({ classes, number, currency = '', variant = 'h6' }) => {
    const [showDetailNumer, setShowDetailNumber] = useState(false);
    const handleSwitchNumber = () => {
        setShowDetailNumber(!showDetailNumer);
    }
    let roundedNumber = Math.round(Number(number));
    return (
        <Typography onClick={handleSwitchNumber} className={classes} component="span" variant={variant}>
            {`${showDetailNumer ? Helper.numberWithCommas(roundedNumber) : Helper.cashShorten(roundedNumber)} ${currency}`}
        </Typography>
    )
}

const TopCard = ({ CLItem, CLData, currentView }) => {
    let currentViewKey = currentView === "middle" ? "MidBalance_ByCashflowLine" : currentView === "left" ? "StartBalance_ByCashflowLine" : "EndBalance_ByCashflowLine";
    const sumVND = Object.values(CLData[CLItem]).reduce((acc, curr) => {
        if (curr["Currency_ByCashflowLine"] === "VND") {

            return acc + parseInt(curr[currentViewKey])
        }
        return acc + 0
    }, 0)
        ;
    const sumUSD = Object.values(CLData[CLItem]).reduce((acc, curr) => {
        if (curr["Currency_ByCashflowLine"] === "USD"){

            return acc + parseInt(curr[currentViewKey])
        }
        return acc + 0
    }, 0)
        ;
    return (
        <div key={CLItem} className="collapse-card-top">
            <div className="collapse-card-title top-card-title">
                {CLItem.substr(CLItem.indexOf(" "))}
            </div>
            <div
                className={'collapse-card-description top-card-descript '}
            >
                <NumberDisplay
                    classes={`collapse-card-top-desc ${sumVND < 0 ? 'red' : sumVND > 0 ? 'green' : ''}`}
                    number={sumVND}
                    currency={'VND'}
                />
                <NumberDisplay
                    classes={`collapse-card-top-desc ${sumUSD < 0 ? 'red' : sumUSD > 0 ? 'green' : ''}`}
                    number={sumUSD}
                    currency={'VND*'}
                />
            </div>
        </div>
    )
}

const BotCard = ({ currentView, CLItemData, ParentKey, CLChildrenData, collapseCard }) => {
    Object.values(CLItemData).sort((a, b) => a["Children_ByCashflowLine"] > b["Children_ByCashflowLine"] ? 1 : -1);
    const [expand, setExpandCard] = useState(false);
    const [id, setId] = useState(CLItemData["id"]);
    let idCard = 0;
    // const idBotCard = Object.values(CLItemData).map((e) => setId(e["id"]))
    const handleExpandCard = (id) => {
        
        idCard = id;
        console.log("id idCard: ", idCard)

        this.setState({ expand: this.state.expand, [id] : true });
        // if(idBotCard === id) {
            setExpandCard(!expand);

        
            return;
        // } else {
        //     setExpandCard(expand);
        //     return;
        // }
    };
    let botCard = Object.values(CLItemData).map((line) => {
        const currentViewKey = currentView === "middle" ? "MidBalance_ByCashflowLine" : currentView === "left" ? "StartBalance_ByCashflowLine" : "EndBalance_ByCashflowLine";
        return (
            <div key={line["Children_ByCashflowLine"] + line["Currency_ByCashflowLine"]}>
                {/* <Divider /> */}
                <Card variant='elevation' elevation={4}>
                    <div className='collapse-card-bottom' style={{display: 'flex', flexDirection: 'column', paddingTop: '16px'}}>
                        <Accordion>
                            <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            >
                                  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: '6px', width: '100%'}}>
                                <div
                                className='collapse-card-title bot-card-title'>
                                {line["Children_ByCashflowLine"]}
                                
                            </div>
                            
                            <div className={'collapse-card-description bot-card-descript'}>
                                <NumberDisplay
                                    classes={`${line[currentViewKey] < 0 ? 'red' : line[currentViewKey] > 0 ? 'green' : ''}`}
                                    number={line[currentViewKey]}
                                    currency={line["Currency_ByCashflowLine"] === 'VND'? 'VND' : 'VND*'}
                                    variant='body1'
                                /> 
                            </div>
                            </div>
                            </AccordionSummary>
                            <AccordionDetails>
                            <ListItem>
                            <CollapseCard
                              children1={
                                <BotsCard
                                        CLChildrenData={CLChildrenData}
                                        currentView={currentView}
                                        ParentKey = {ParentKey}
                                        ChilrenKey = { line.Children_ByCashflowLine}
                                    />
                                }
                            />
                            </ListItem>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                 </Card>
                </div>
        )
    })
    return (<>{botCard}</>)
}

const BotsCard = ({ currentView, CLChildrenData, ParentKey, ChilrenKey}) => {
    let ChilDataFil = Object.values(CLChildrenData).filter(abc => abc.Parent === ParentKey && abc.Children === ChilrenKey );
    let botsCard = ChilDataFil.map((line) => {
        const currentViewKey = currentView === "middle" ? "MidBalance" : currentView === "left" ? "StartBalance" : "EndBalance";
        return (
            <div key={line["Parent"] + line["Chilren"]}>
                <Divider/>
                <div className='collapse-card-bottom-item'>
                    <div
                        className='collapse-card-title bots-card-title'>
                        {line["Legal"]}
                    </div>
                    <div
                        className={'collapse-card-description bot-card-descript '}
                    >
                        <NumberDisplay
                            classes={`${line[currentViewKey] < 0 ? 'red' : line[currentViewKey] > 0 ? 'green' : ''}`}
                            number={line[currentViewKey]}
                            currency={line["Currency"] === 'VND'? 'VND' : 'VND*'}
                            variant='body1'
                        />
                    </div>
                </div>
            </div>
        )
    })
    return (<>{botsCard}</>)
}

export default PurposePage
