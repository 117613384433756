import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import IconButton from '@material-ui/core/IconButton';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Collapse } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        // flexWrap: 'wrap',
        justifyContent: 'center',
        alignContent: 'center',
        width: '100%',
        '& .MuiCollapse-wrapperInner': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& .MuiButton-label': {
            display: 'flex',
            flexDirection: 'column',
        },
        '& .MuiButton-label > div': {
            width: '100%',
        },
        '& .MuiButtonBase-root': {
            display: 'unset',
        },
        '& .MuiButton-outlinedPrimary': {
            border: 'none',
        },
        '& .MuiButton-outlined': {
            padding: 0,
        },
        '& .MuiButton-root': {
            padding: 0,
        },
    },
  });

const CollapseCard = ({children1, children2, collapse}) => {
    const classes = useStyles();
    const [expand, setExpandCard] = useState(false);
    const handleExpandCard = () => {
        setExpandCard(!expand);
    };
    return (
        <Card variant='elevation' elevation={4} className={classes.root}>
            {children1}
            { collapse &&<IconButton onClick={handleExpandCard} className={'expand ' + (expand ? 'expand-open' : "")}>
                <ExpandMoreIcon />
            </IconButton>}
            <Collapse in={expand} style={{ width: '100%' }}>
                {children2}              
            </Collapse>
        </Card>
    );
};

export default CollapseCard;
