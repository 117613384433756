import React, { useState } from 'react'
import moment from "moment";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import SvgIcon from '@material-ui/core/SvgIcon';
import HomeCard from './components/HomeCard';
import PageHeader from './components/PageHeader';
import BalanceOptions from './components/BalanceOptions';
import Grow from '@material-ui/core/Grow';
import { useLocation } from 'react-router-dom';
import svgIcons from './components/SvgIcoPath';

const HomePage = ({ homeData }) => {
    const [value, setValue] = useState('middle');
    const [currentView, setViewClass] = useState('middle');
    const handleBalanceOption = (evt, newValue) => {
        setValue(newValue)
        setViewClass(newValue);
    }

    ///For Grow Effect
    const location = useLocation();
    const check = location.pathname === "/home";
    const listItems = homeData.map((info) =>
        <React.Fragment key={info.type}>
            <Grow in={check} timeout={800}>
                <ListItem alignItems='center'>
                    <HomeCard
                        className={currentView}
                        classes1={`${info[currentView].vnd < 0 ? 'red' : info[currentView].vnd > 0 ? 'green' : ''}`}
                        classes2={`${info[currentView].usd < 0 ? 'red' : info[currentView].usd > 0 ? 'green' : ''}`}
                        title1='Tiền Việt'
                        title2='Ngoại tệ'
                        des1={info[currentView].vnd}
                        des2={info[currentView].usd}
                    >
                        <SvgIcon className='balance ico-background' viewBox='0 0 50 50' fontSize='large'>
                        ${info.icon == "undefinedIco" ? <path stroke='currentColor' strokeWidth={"1"} /> : <path stroke='currentColor' strokeWidth={"1"} d={svgIcons[info.icon].path} />}
                         
                        </SvgIcon>
                        <div className='balance'>{info.type}</div>
                    </HomeCard>
                </ListItem>
            </Grow>
            <Divider variant='middle' />
        </React.Fragment>
    )

    return (
        <div className='page-container'>
            <PageHeader
                lastUpdatedTime={moment().format("MMMM Do YYYY, h:mm A")}
                pageHeader='TIỀN HIỆN CÓ'
            />
            <BalanceOptions
                value={value}
                handleTabChange={handleBalanceOption}
                tabLabels={['Đầu kỳ', 'Phát sinh', 'Lũy kế']}
                tabValues={['left', 'middle', 'right']}
            />
            <List >
                {listItems}
            </List>

        </div>
    )
}

export default HomePage
