import React, { useState } from "react";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import CardContent from "@material-ui/core/CardContent";
import Helper from '../controller/Helper';
const HomeCard = (props) => {
  return (
    <Card variant='elevation' elevation={5} className='card-home'>
      <div className='card-icon-text'>{props.children}</div>
      <CardContent className='card-content'>
        <Typography variant="body1">{props.title1}</Typography>
        <NumberDisplay classes={props.classes1} number={props.des1} currency={'VND'} />
        <Typography variant="body1">{props.title2}</Typography>
        <NumberDisplay classes={props.classes2} number={props.des2} currency={'VND'} />
      </CardContent>
    </Card>
  );
};

const NumberDisplay = ({ classes, number, currency }) => {
  const [showDetailNumer, setShowDetailNumber] = useState(false);
  const handleSwitchNumber = () => {
    setShowDetailNumber(!showDetailNumer);
  }
  let roundedNumber = Math.round(number)
  return (
    <Typography onClick={handleSwitchNumber} className={classes} component="span" variant="h6">
      {`${showDetailNumer ? Helper.numberWithCommas(roundedNumber) : Helper.cashShorten(roundedNumber)} ${currency}`}
    </Typography>
  )
}

export default HomeCard;
