import React, { useState } from "react";
import moment from "moment";
import { Pie } from 'react-chartjs-2';
import { ButtonGroup, Button } from '@material-ui/core'
import PageHeader from './components/PageHeader';
import BalanceOptions from './components/BalanceOptions';
import Helper from './controller/Helper'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CollapseCard from './components/CollapseCard';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const BankPage = ({ bankData, flatData }) => {
    const totalData = {
        totalVNDStart: flatData.reduce((acc, curr) => acc + curr["VNDStart_ByBank"], 0),
        totalVNDMid: flatData.reduce((acc, curr) => acc + curr["VNDMid_ByBank"], 0),
        totalVNDEnd: flatData.reduce((acc, curr) => acc + curr["VNDEnd_ByBank"], 0),
        totalUSDStart: flatData.reduce((acc, curr) => acc + curr["USDStart_ByBank"], 0),
        totalUSDMid: flatData.reduce((acc, curr) => acc + curr["USDMid_ByBank"], 0),
        totalUSDEnd: flatData.reduce((acc, curr) => acc + curr["USDEnd_ByBank"], 0)
    }
    const [totalBank, setTotalBank] = useState({ totalVND: totalData.totalVNDMid, totalUSD: totalData.totalUSDMid });
    const bankCardData = Object.keys(bankData).map(title => {
        let startVNDTotal = 0;
        let startUSDTotal = 0;
        let midVNDTotal = 0;
        let midUSDTotal = 0;
        let endVNDTotal = 0;
        let endUSDTotal = 0;
        bankData[title].forEach((line) => {
            startVNDTotal += line["VNDStart_ByBank"];
            startUSDTotal += line["USDStart_ByBank"];
            midVNDTotal += line["VNDMid_ByBank"];
            midUSDTotal += line["USDMid_ByBank"];
            endVNDTotal += line["VNDEnd_ByBank"];
            endUSDTotal += line["USDEnd_ByBank"];
        });
        return {
            title,
            left: {
                top: [`${startVNDTotal || 0}`, `${startUSDTotal || 0}`],
                bot: bankData[title].map((detail) => {
                    return {
                        type: detail["AccNo_ByBank"],
                        vndAmount: detail["VNDStart_ByBank"],
                        usdAmount: detail["USDStart_ByBank"]
                    }
                })
            },
            middle: {
                top: [`${midVNDTotal || 0}`, `${midUSDTotal || 0}`],
                bot: bankData[title].map((detail) => {
                    return {
                        type: detail["AccNo_ByBank"],
                        vndAmount: detail["VNDMid_ByBank"],
                        usdAmount: detail["USDMid_ByBank"]
                    }
                })
            },
            right: {
                top: [`${endVNDTotal || 0}`, `${endUSDTotal || 0}`],
                bot: bankData[title].map((detail) => {
                    return {
                        type: detail["AccNo_ByBank"],
                        vndAmount: detail["VNDEnd_ByBank"],
                        usdAmount: detail["USDEnd_ByBank"]
                    }
                })
            }
        }
    });
    const [activeCurrency, setActiveCurrency] = useState("VND");
    const [value, setValue] = useState('middle');
    const [updateChart, setUpdateChart] = useState(false);
    const [currentView, setViewClass] = useState('middle');

    const [data, setChartData] = useState(bankCardData.map((unit) => {
        let strCash = unit[currentView].top[activeCurrency === 'VND' ? 0 : 1];
        return Number(strCash) >= 0 ? Number(strCash) : 0
    }));

    const handleChangeChartData = (currency) => {
        setActiveCurrency(currency);
        setChartData(bankCardData.map((unit) => {
            let strCash = unit[currentView].top[currency === 'VND' ? 0 : 1];
            return Number(strCash) >= 0 ? Number(strCash) : 0
        }))
        setUpdateChart(true)
    }

    const handleTabChange = (evt, newValue) => {
        setValue(newValue);
        setViewClass(newValue);
        switch (newValue) {
            case "middle":
                setTotalBank({ totalVND: totalData.totalVNDMid, totalUSD: totalData.totalUSDMid });
                break;
            case "left":
                setTotalBank({ totalVND: totalData.totalVNDStart, totalUSD: totalData.totalUSDStart });
                break;
            case "right":
                setTotalBank({ totalVND: totalData.totalVNDEnd, totalUSD: totalData.totalUSDEnd });
                break;
            default:
                setTotalBank({ totalVND: 0, totalUSD: 0 });
        }
        setChartData(bankCardData.map((unit) => {
            let strCash = unit[newValue].top[activeCurrency === 'VND' ? 0 : 1];
            return Number(strCash) >= 0 ? Number(strCash) : 0
        }))
        setUpdateChart(true)
    }


    //Chart colors
    const backgroundColor = [
        'rgba(252, 3, 3, 0.7)',
        'rgba(252, 194, 1, 0.7)',
        'rgba(60,179,113, 0.7)',
        'rgba(3, 165, 252, 0.7)',
        'rgba(25,10,99,0.7)',
        'rgba(254, 132, 14,0.7)',
        'rgba(200,231,44,0.7',
        'rgba(165,233,44,0.7',
        'rgba(23,55,230,0.7',
        'rgba(50,255,40,0.7',
        'rgba(255,99,100,0.7',
        'rgba(220,150,255,0.7)',
        'rgba(150,220,255,0.7'
    ]

    ///Whole list
    const list = bankCardData.map((item, index) => {
        return (
            <ListItem key={item.title}>
                <CollapseCard
                    children1={
                        <TopCard
                            currentView={currentView}
                            title={item.title}
                            topDescr={item[currentView].top}
                            color={backgroundColor[index]}
                        />
                    }
                    children2={
                        <BotCard
                            currentView={currentView}
                            item={item}
                        />
                    }
                    collapse={true}
                />
            </ListItem>
        )
    });

    //Chart Stuff
    let chartConfig = {
        labels: Object.keys(bankData),
        datasets: [{
            data: data,
            backgroundColor: backgroundColor,
            hoverBackgroundColor: [
                'rgba(252, 3, 3, 1)',
                'rgba(252, 194, 1, 1)',
                'rgba(60,179,113, 1)',
                'rgba(3, 165, 252, 1)',
                'rgba(25,10,99,1)',
                'rgba(254, 132, 14,1)',
                'rgba(200,231,44,1',
                'rgba(165,233,44,1',
                'rgba(23,55,230,1',
                'rgba(50,255,40,1',
                'rgba(255,99,100,1',
                'rgba(220,150,255,1)',
                'rgba(150,220,255,1'
            ]
        }]
    };

    const currencyList = ['VND', 'Ngoại tệ'].map(currency =>
        <Button
            key={currency}
            color={activeCurrency === currency ? "primary" : "default"}
            onClick={() => handleChangeChartData(currency)}
        >
            {currency}
        </Button>
    );

    return (
        <div className='page-container'>
            <PageHeader
                pageHeader='XEM TÀI SẢN THEO NGÂN HÀNG'
                lastUpdatedTime={moment().format("MMMM Do YYYY, h:mm A")}
            />
            <div className='btn-group-container'>
                <ButtonGroup variant='contained' size='small' disableElevation>
                    {currencyList}
                </ButtonGroup>
            </div>
            <div className='chart-legal-doughnut'>
                <Pie
                    data={chartConfig}
                    width={100}
                    height={100}
                    redraw={updateChart}
                    options={
                        {
                            title: {
                                display: true,
                                text: "Biểu đồ tỷ lệ tài sản theo ngân hàng ",
                                position: 'bottom',
                                fontSize: 16
                            },
                            tooltips: {
                                callbacks: {
                                    label: function (tooltipItem, data) {
                                        let dataset = data.datasets[tooltipItem.datasetIndex];
                                        let meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                        let total = meta.total;
                                        let currentValue = dataset.data[tooltipItem.index];
                                        let percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                        return Helper.cashShorten(currentValue) + ' (' + percentage + '%)';
                                    },
                                    title: function (tooltipItem, data) {
                                        return data.labels[tooltipItem[0].index];
                                    }
                                }
                            }
                        }
                    }
                />
            </div>

            <div className='total-display'>
                <p>Tổng {value==='middle'? 'phát sinh' : value ==='left'? 'đầu kỳ': 'lũy kế'}</p>
                <NumberDisplay
                    classes='neutral'
                    number={totalBank.totalVND}
                    currency={`VND`} 
                />
            </div>
            <Typography className='page-annotation' variant='caption'>VND*: số tiền VND chuyển đổi từ ngoại tệ</Typography>
            <BalanceOptions
                value={value}
                handleTabChange={handleTabChange}
                tabLabels={['Đầu kỳ', 'Phát sinh', 'Lũy kế']}
                tabValues={['left', 'middle', 'right']}
            />
            <List>
                {list}
            </List>
        </div>
    );
};

const NumberDisplay = ({ classes, number, currency = '', variant = 'h6' }) => {
    const [showDetailNumer, setShowDetailNumber] = useState(false);
    const handleSwitchNumber = () => {
        setShowDetailNumber(!showDetailNumer);
    }
    let roundedNumber = Math.round(Number(number));
    return (
        <Typography onClick={handleSwitchNumber} className={classes} component="span" variant={variant}>
            {`${showDetailNumer ? Helper.numberWithCommas(roundedNumber) : Helper.cashShorten(roundedNumber)} ${currency}`}
        </Typography>
    )
}

const TopCard = ({ title, currentView, topDescr }) => {
    let topcard =
        <div className="collapse-card-top">
            <div
                className="collapse-card-title top-card-title">{title}</div>
            <div
                className={'collapse-card-description top-card-descript '}
            >
                {
                    <>
                        <NumberDisplay
                            classes={`collapse-card-top-desc ${Number(topDescr[0]) < 0 ? 'red' : Number(topDescr[0]) > 0 ? 'green' : ''}`}
                            number={topDescr[0]}
                            currency={'VND'}
                        />
                        <NumberDisplay
                            classes={`collapse-card-top-desc ${Number(topDescr[1]) < 0 ? 'red' : Number(topDescr[1]) > 0 ? 'green' : ''}`}
                            number={topDescr[1]}
                            currency={'VND*'}
                        />
                    </>
                }
            </div>
        </div>
    return (
        <>
            {topcard}
        </>
    )
}

const BotCard = ({ item, currentView }) => {
    const sortedItem = [...item[currentView].bot].sort(function (a, b) {
        return a.type === b.type ? 0 : +(a.type > b.type) || -1;
    });

    let botCard = sortedItem.map(detail =>
        <div key={detail.type}>
            <Divider />
            <div className='collapse-card-bottom'>
                <div
                    className='collapse-card-title bot-card-title'>
                    {detail.type}
                </div>
                <div
                    className={'collapse-card-description bot-card-descript '}
                >
                    <NumberDisplay
                        classes={detail.vndAmount < 0 ? 'red' : detail.vndAmount > 0 ? 'green' : ''}
                        number={detail.vndAmount}
                        currency={'VND'}
                        variant='body1'
                    />
                    {
                        detail.usdAmount !== 0 &&
                        <NumberDisplay
                            classes={detail.usdAmount < 0 ? 'red' : detail.usdAmount > 0 ? 'green' : ''}
                            number={detail.usdAmount}
                            currency={'VND*'}
                            variant='body1'
                        />
                    }
                </div>
            </div>
        </div>
    )
    return (
        <>
            {botCard}
        </>
    )
}
export default BankPage
