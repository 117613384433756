import React from 'react'
const PageHeader = ({pageHeader,lastUpdatedTime}) => {
    return (
        <div className='page-header'>
            <h4 className='page-header-heading'>{pageHeader}</h4>
            <span style={{ fontWeight: 500,fontSize: '14px'}}>Last Update</span>
            <br />
            <span style={{ fontSize: "12px" }}>
                {lastUpdatedTime}
            </span>
        </div>
    )
}

export default PageHeader
