import moment from 'moment';
import { authProvider } from '../components/MsalAuth';
import Helper from './Helper';
const baseUrl = 'https://graph.microsoft.com/v1.0';
const dev = 'ttgvncom.sharepoint.com,7fcd3a61-cdb7-4fdc-a206-a2fdb81f2948,4e51d014-6551-43f8-a875-e54f86f0f621';

const devUrl = 'ttgvncom.sharepoint.com,2c7537f5-79a4-4b73-851a-6b18e6870899,2c9d889f-08bc-4e09-b5aa-5919ada9ac25'

const fetchSPtoken = async (method) => {
    const token = await authProvider.getAccessToken();
    return {
        method: method || "GET",
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
            'Content-Type': 'application/json'
        }
    }

    // const finalResult = Helper.groupBy(legalResult.value,"Unit_ByLegal")
    // return finalResult;
    // let output = []
    // let moreItems = true;
    // let url = `${baseUrl}/sites/${devUrl}/lists/ByLegal_CashTracking/items?$top=4000`;
    // while(moreItems){
    //     const result = await fetch(url,options);
    //     let parsedResult = await result.json();
    //     output.push(parsedResult.value);
    //     if(parsedResult['@odata.nextLink']){
    //         url = parsedResult['@odata.nextLink'];
    //     } else {
    //         moreItems = false;
    //     }
    // };
    // return await output.flat()
};

const getLegalData = async () => {
    const url = `${baseUrl}/sites/${devUrl}/lists/ByLegal_CashTracking/items?expand=fields(select=Unit_ByLegal,Type_ByLegal,VNDStart_ByLegal,USDStart_ByLegal,VNDMid_ByLegal,USDMid_ByLegal,VNDEnd_ByLegal,USDEnd_Bylegal,id)&$top=4000&orderby=Unit_ByLegal`;
    const resultLegal = await fetch(url, await fetchSPtoken());
    const parsedResult = await resultLegal.json();
    const legalData = parsedResult.value.map(value => value.fields);
    const groupedLegalData = Helper.groupBy(legalData, "Unit_ByLegal");
    return groupedLegalData
}


const getHomeData = async () => {
    const url = `${baseUrl}/sites/${devUrl}/lists/ByLegal_CashTracking/items?expand=fields(select=Unit_ByLegal,Type_ByLegal,VNDStart_ByLegal,USDStart_ByLegal,VNDMid_ByLegal,USDMid_ByLegal,VNDEnd_ByLegal,USDEnd_Bylegal,id)&$top=4000&orderby=Unit_ByLegal`;
    const resultHome = await fetch(url, await fetchSPtoken());
    const parsedResult = await resultHome.json();
    const homeData = parsedResult.value.map(value => value.fields);
    const groupedHomeData = Helper.groupBy(homeData, "Type_ByLegal");
    const datas = Object.keys(groupedHomeData).map((type) => {
        let sumVNDStart = 0;
        let sumVNDMid = 0;
        let sumVNDEnd = 0;
        let sumUSDStart = 0;
        let sumUSDMid = 0;
        let sumUSDEnd = 0;
        groupedHomeData[type].forEach((record) => {
            sumVNDStart += record["VNDStart_ByLegal"];
            sumVNDMid += record["VNDMid_ByLegal"];
            sumVNDEnd += record["VNDEnd_ByLegal"];
            sumUSDStart += record["USDStart_ByLegal"];
            sumUSDMid += record["USDMid_ByLegal"];
            sumUSDEnd += record["USDEnd_ByLegal"];
        })
        return {
            type,
            icon: type + "Ico",
            left: {
                vnd: sumVNDStart,
                usd: sumUSDStart
            },
            middle: {
                vnd: sumVNDMid,
                usd: sumUSDMid
            },
            right: {
                vnd: sumVNDEnd,
                usd: sumUSDEnd
            }
        }
    });
    return datas
}


const getBankData = async () => {
    const url = `${baseUrl}/sites/${devUrl}/lists/ByBank_CashTracking/items?expand=fields(select=Bank_ByBank,AccNo_ByBank,AccCode_ByBank,VNDStart_ByBank,USDStart_ByBank,VNDMid_ByBank,USDMid_ByBank,VNDEnd_ByBank,USDEnd_ByBank,id)&$top=4000&orderby=Bank_ByBank`;
    const resultBank = await fetch(url, await fetchSPtoken());
    const parsedResult = await resultBank.json();
    const bankData = parsedResult.value.map(value => value.fields);
    const groupedBankData = Helper.groupBy(bankData, "Bank_ByBank");
    return groupedBankData
}

const getCashflowLine = async () => {
    const url = `${baseUrl}/sites/${devUrl}/lists/ByCashflowLine_CashTracking/items?expand=fields(select=Parent_ByCashflowLine,Children_ByCashflowLine,Currency_ByCashflowLine,StartBalance_ByCashflowLine,MidBalance_ByCashflowLine,EndBalance_ByCashflowLine,id)&$top=4000&orderby=Parent_ByCashflowLine`;
    const resultCL = await fetch(url, await fetchSPtoken());
    const parsedResult = await resultCL.json();
    const CLData = parsedResult.value.map(value => value.fields);
    const groupedCLData = Helper.groupBy(CLData, "Parent_ByCashflowLine");

   // console.log("CLData", CLData, "groupedCLData", groupedCLData )
    return groupedCLData
}

const getChildrenCashflowLine = async() => {
    const url = `${baseUrl}/sites/${devUrl}/lists/LineDetail_CashTracking/items?expand=fields(select=Parent,Children,Currency,Legal,StartBalance,MidBalance,EndBalance,ChildrenTest,id)&$top=4000&orderby=Parent`;
    const resultCL = await fetch(url, await fetchSPtoken());
    const parsedResult = await resultCL.json();
    const CLChildrenData = parsedResult.value.map(value => value.fields);
    //const groupedCLChildrenData = Helper.groupBy(CLChildrenData, "Parent");
  // console.log("CLChildrenData ", CLChildrenData);
    return CLChildrenData
}

///12 months
const getAllCFIData = async () => {
    const requestData = {
        requests: [
        ]
    }
    for (let i = 1; i <= 12; i++) {
        requestData.requests.push({
            id: i,
            method: "GET",
            url: `/sites/${devUrl}/lists/T${i}_CashTracking/items?expand=fields(select=Unit_CashTracking,Type_CashTracking,Currency_CashTracking,Bank_CashTracking,AccNo_CashTracking,Item_CashTracking,Headcode_CashTracking,Balance_CashTracking,OffAcc_CashTracking,id)&$top=4000&orderby=Item_CashTracking`
        })
    }

    const token = await authProvider.getAccessToken();
    const options = {
        method: "POST",
        headers: {
            Authorization: `Bearer ${token.accessToken}`,
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestData)
    }

    const resultAllCFI = await fetch("https://graph.microsoft.com/v1.0/$batch", options);
    const parsedResult = await resultAllCFI.json();
    parsedResult.responses.sort((a, b) => parseInt(a.id) > parseInt(b.id) ? 1 : -1);
    const arrAllCFI = parsedResult.responses.map((month) =>
        month.body.value.map((item) => item.fields)
    );
    const arrCleanedCFI = arrAllCFI.map(month => (month.filter((line) => line["Item_CashTracking"] !== undefined)));
    const dataByFlowMonthly = Object.entries(arrCleanedCFI).map(([key, value]) => {
        ///Month name
        const month = `T${parseInt(key) + 1}`;
        return {
            month,
            inflow: value.reduce((acc, curr) => {
                return curr["Balance_CashTracking"] > 0 ? acc + curr["Balance_CashTracking"] : acc + 0
            }, 0),
            outflow: value.reduce((acc, curr) => {
                return curr["Balance_CashTracking"] < 0 ? acc + curr["Balance_CashTracking"] : acc + 0
            }, 0)
        }
    }
    );

    //FOR CALCULATION TESTING
    // const flattenArrAllCFI = arrCleanedCFI.flat();
    // const dataByFlow =
    // {
    //     inflow: flattenArrAllCFI.reduce((acc, curr) => {
    //         return curr["Balance_CashTracking"] > 0 ? acc + curr["Balance_CashTracking"] : acc + 0

    //     }, 0),
    //     outflow: flattenArrAllCFI.reduce((acc, curr) => {
    //         return curr["Balance_CashTracking"] < 0 ? acc + curr["Balance_CashTracking"] : acc + 0
    //     }, 0)
    // }
    return dataByFlowMonthly
}

export default { getLegalData, getBankData, getCashflowLine, getAllCFIData, getHomeData, getChildrenCashflowLine }