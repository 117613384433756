import React, { useState, useEffect } from "react";
import moment from "moment";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import AppBar from "@material-ui/core/AppBar";
import HomeIcon from "@material-ui/icons/Home";
import GavelIcon from '@material-ui/icons/Gavel';
import AccountBalanceIcon from "@material-ui/icons/AccountBalance";
import ListAltIcon from '@material-ui/icons/ListAlt';
import MenuIcon from "@material-ui/icons/Menu";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import HomePage from "./HomePage";
import LegalPage from "./LegalPage";
import BankPage from './BankPage';
import PurposePage from './PurposePage';
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter as Router, Route, Link, Redirect } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import FetchData from './controller/FetchData';
const globalTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#5490b7",
      main: "#1c6387",
      dark: "#00395a",
      contrastText: "#fff",
    },
    secondary: {
      light: "#a6bfcc",
      main: "#778f9b",
      dark: "#4a626d",
      contrastText: "#000000",
    },
  },
});

const theme = createMuiTheme({
  palette: globalTheme.palette,
  overrides: {
    MuiBottomNavigation: {
      root: {
        backgroundColor: "#1c6387",
      },
    },
    MuiBottomNavigationAction: {
      root: {
        color: "#778f9b",
        "&$selected": {
          color: "#fff",
        },
      },
    },
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: globalTheme.palette.primary.main,
      },
    },
    MuiIconButton: {
      root: {
        color: globalTheme.palette.primary.secondary,
      },
    },
    MuiTabs: {
      indicator: {
        background: globalTheme.palette.primary.main
      }
    },
    MuiTab: {
      textColorPrimary: {
        color: globalTheme.palette.secondary.main,
        '&$selected': {
          color: globalTheme.palette.primary.main
        }
      }
    }
  },
});

const App = ({ logout }) => {
  const linkStyle = { textDecoration: "none", padding: 0 };
  const [value, setValue] = useState(window.location.pathname !== "/" ? window.location.pathname : "/home");
  const [drawerOpen, setDrawer] = useState(false);
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawer(open);
  };
  const initialStateCLChildrenData = {
    value: {
      fields: {
        id: "",
        Currency: "",
        Legal: "",
        StartBalance: "",
        MidBalance: "",
        EndBalance: "",
      }
    }
  }
  const initialStateCLData = {
    value: {
      fields: {
        id: "",
        Children_ByCashflowLine: "",
        Currency_ByCashflowLine: "",
        EndBalance_ByCashflowLine: 0,
        MidBalance_ByCashflowLine: 0,
        Parent_ByCashflowLine: "",
        StartBalance_ByCashflowLine: 0,
      }
    }
  }

  const [homeData, setHomeData] = useState([]);
  const [legalData, setLegal] = useState([]);
  const [bankData, setBank] = useState([]);
  const [CLData, setCL] = useState(initialStateCLData);
  const [CLChildrenData, setCLChildrenData] = useState(initialStateCLChildrenData);
  const [allCFIData,setCFIData] = useState([])
  useEffect(() => {
    setLoading(true);
    const promise1 = FetchData.getLegalData()
      .then((res) => {
        setLegal(res);
      })
      .catch((err) => {
        console.log(err)
      });

    const promise2 = FetchData.getBankData()
      .then((res) => {
        delete res.CASH;
        delete res.TDANGCHUYEN;
        setBank(res);
      })
      .catch((err) => {
        console.log(err)
      });

    const promise3 =FetchData.getCashflowLine()
      .then((res) => {
        delete res.undefined
        setCL(res)
      })
      .catch((err) => {
        console.log(err)
      })  
    
    const promise4 = FetchData.getAllCFIData()
      .then((res) => {
        setCFIData(res)
      })
      .catch((err) => {
        console.log(err)
      }) 

    const promise5 = FetchData.getHomeData()
      .then((res) => {
        setHomeData(res);
      })
      .catch((err) => {
        console.log(err)
      });
    
    const promise6 = FetchData.getChildrenCashflowLine()
      .then((res) => {
        delete res.undefined
        setCLChildrenData(res)
      })
      .catch((err) => {
        console.log(err)
      }) 
    Promise.all([promise1,promise2,promise3,promise4,promise5,promise6]).then(() => 
      setLoading(false)
    );
  }, []);

  const [loading, setLoading] = useState(false);
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Backdrop style={{ zIndex: 1500 }} open={loading}>
          <CircularProgress />
        </Backdrop>
        <ThemeProvider theme={theme}>
          <AppBar position="fixed" color="primary">
            <Toolbar>
              <IconButton onClick={toggleDrawer(true)} edge="start">
                <MenuIcon />
              </IconButton>
              <Typography style={{ flexGrow: 1 }} variant="h6">
                {moment().format("MMMM Do YYYY")}
              </Typography>
              <Typography variant='overline'>
                V0.4.0
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer anchor={'left'} open={drawerOpen} onClose={toggleDrawer(false)}>
            <List style={{ width: '180px' }}>
              <ListItem button onClick={logout}>
                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary={<Typography variant='button'>Logout</Typography>} />
              </ListItem>
            </List>
          </Drawer>
          <Route exact path='/'>
            <Redirect from='/' to='/home' />
          </Route>
          <Route exact path="/home">
            <div className="fade-in">
              <HomePage homeData={homeData} />
            </div>
          </Route>
          <Route exact path="/legal">
            <div className="fade-in">
              <LegalPage legalData={legalData}  flatData={Object.values(legalData).flat()}/>
            </div>
          </Route>
          <Route exact path="/bank">
            <div className="fade-in">
              <BankPage bankData={bankData} flatData = {Object.values(bankData).flat()} />
            </div>
          </Route>
          <Route exact path="/purpose">
            <div className="fade-in">
              <PurposePage
                showLoading={setLoading}
                CLData = {CLData}
                CLChildrenData = {CLChildrenData}
                CFIData = {allCFIData}
                flatData= {Object.values(CLData).flat()}
              />
            </div>
          </Route>
          <BottomNavigation
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            showLabels={false}
            className={"bottom-app-nav"}
          >
            <BottomNavigationAction
              label="Home"
              icon={<HomeIcon />}
              value={"/home"}
              component={Link}
              to="/home"
              style={linkStyle}
            />
            <BottomNavigationAction
              label="Legal"
              icon={<GavelIcon />}
              value={"/legal"}
              component={Link}
              to="/legal"
              style={linkStyle}
            />

            <BottomNavigationAction
              label="Bank"
              icon={<AccountBalanceIcon />}
              value={"/bank"}
              component={Link}
              to="/bank"
              style={linkStyle}
            />
            <BottomNavigationAction
              label="Purpose"
              icon={<ListAltIcon />}
              value={"/purpose"}
              component={Link}
              to="/purpose"
              style={linkStyle}
            />
          </BottomNavigation>
        </ThemeProvider>
      </Router>
    </div>
  );
}

export default App;
