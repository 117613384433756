import React, {useState,useEffect} from 'react'
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import * as serviceWorker from '../serviceWorker';
const ServiceWorkerUpdate = () => {
    const [update,setShowUpdate] = useState(false);
    const [waitingWorker, setWaitingWorker] = useState(null);
    const onSWUpdate = (registration) => {
        setShowUpdate(true)
        setWaitingWorker(registration.waiting)
    }
    
    useEffect(() => {
        serviceWorker.register({onUpdate: onSWUpdate});
    },[]);

    const reloadPage = () => {
        if(waitingWorker) waitingWorker.postMessage({type: 'SKIP_WAITING'});
        setShowUpdate(false);
        window.location.reload();
    }
    const action =
        <Button variant='contained' onClick={reloadPage}>
            Update
        </Button>
    return (
        <Snackbar
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'center'
        }}
        open={update}
        autoHideDuration={10000}
        message='A new update is available'
        action={action}
        />
    )
}

export default ServiceWorkerUpdate
