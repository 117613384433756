import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {AzureAD, AuthenticationState} from 'react-aad-msal';
import {authProvider} from './components/MsalAuth'
import Login from './LoginScreen';
import ServiceWorkerUpdate from './components/ServiceWorkerUpdate';

ReactDOM.render(
  <div>
    <ServiceWorkerUpdate />
    <AzureAD provider = {authProvider}>
      {
          ({login, logout, authenticationState, error, accountInfo}) => {
            switch (authenticationState) {
              case AuthenticationState.Authenticated:
              return (
                  <App logout={logout} />
                );
              case AuthenticationState.Unauthenticated:
                return (
                  <div>
                    <Login login={login} error={error} state={'login'} />
                  </div>
                );
              case AuthenticationState.InProgress:
                return (<Login state={'loading'} />);
              default:
                return 1
            }
          }
      }
    </AzureAD>
    </div>
  ,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA

