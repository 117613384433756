const groupBy = (arr, property) => {
    return arr.reduce((acc,obj) => {
        let key = obj[property]
        if (!acc[key]) {
            acc[key] = []
        }
        acc[key].push(obj)
        return acc
    }, {})
};


////Money Simple Reader
const cashShorten = (labelValue) => {
    return Math.abs(Number(labelValue)) >= 1.0e+9

    ? numberWithCommas(Math.round((Number(labelValue) / 1.0e+9)*100)/100) + " tỷ"
    : Math.abs(Number(labelValue)) >= 1.0e+6

    ? numberWithCommas(Math.round((Number(labelValue) / 1.0e+6)*100)/100) + " triệu"
    : numberWithCommas(Number(labelValue))
}

const cashShorten2 = (labelValue) => {
    return Math.abs(Number(labelValue)) >= 1.0e+6

    ? Math.round((Number(labelValue) / 1.0e+6)*100)/100 + " triệu"
    : Math.abs(Number(labelValue)) >= 1.0e+3

    ? Math.round((Number(labelValue) / 1.0e+3)*100)/100 + ""
    : Number(labelValue);
}

function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default {groupBy, cashShorten, cashShorten2, numberWithCommas}