import {MsalAuthProvider,LoginType} from 'react-aad-msal';

const config = {
    auth: {
        authority: 'https://login.microsoftonline.com/801edb4c-29ba-4d07-80b1-16a955dbf20d',
        clientId:'063afac6-fb89-4c97-acd1-b0fe8007c2ef',
        redirectUri: window.location.origin,
        postLogoutRedirectUri: window.location.origin
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: true
    }
}

const authenticationParameters = {
    scopes: [
      'user.read'
    ]
  }

const options = {
    loginType: LoginType.Redirect,
    tokenRefreshUri: window.location.origin + '/auth.html'
};

export const authProvider = new MsalAuthProvider(config,authenticationParameters,options);