import React from 'react';
import Button from '@material-ui/core/Button';
import Background from './img/backgroundttg.jpg';
// import Typography from '@material-ui/core/Typography'
import {useTheme} from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const LoginScreen = ({login,state,error}) => {
    const theme = useTheme();
    const style = {
        background: {
                backgroundImage: `url(${Background})`
            },
        btn: {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
            marginTop: '48px',
            padding: '0.5em 1.3em',
            
        }
    }
        return (
            <div style={style.background} className={'login-screen'}>
                { state==='loading' ? 
                    <Backdrop style={{zIndex:99}} open={true}>
                        <CircularProgress />
                    </Backdrop>
                        :
                    <Button onClick={login} style={style.btn} variant='contained'>Login</Button>
                }
                {/* {error && <Typography color='error' variant="h6" component="h2">An error has occured, please try again</Typography>} */}
            </div>
        )
    
}

export default LoginScreen
