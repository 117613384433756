import React, { useState } from "react";
import moment from "moment";
import { Pie } from 'react-chartjs-2';
import { ButtonGroup, Button } from '@material-ui/core'
import PageHeader from './components/PageHeader';
import BalanceOptions from './components/BalanceOptions';
import Helper from './controller/Helper'
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import CollapseCard from './components/CollapseCard';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const LegalPage = ({ theme, legalData,flatData }) => {
    const totalData = {
        totalVNDStart: flatData.reduce((acc,curr) => acc + curr["VNDStart_ByLegal"],0),
        totalVNDMid: flatData.reduce((acc,curr) => acc + curr["VNDMid_ByLegal"],0),
        totalVNDEnd: flatData.reduce((acc,curr) => acc + curr["VNDEnd_ByLegal"],0),
        totalUSDStart: flatData.reduce((acc,curr) => acc + curr["USDStart_ByLegal"],0),
        totalUSDMid: flatData.reduce((acc,curr) => acc + curr["USDMid_ByLegal"],0),
        totalUSDEnd: flatData.reduce((acc,curr) => acc + curr["USDEnd_ByLegal"],0)
    }
    const [totalLegal,setTotalLegal] = useState({totalVND: totalData.totalVNDMid, totalUSD: totalData.totalUSDMid});
    const legalCardData = Object.keys(legalData).map(title => {
        let startVNDTotal = 0;
        let startUSDTotal = 0;
        let midVNDTotal = 0;
        let midUSDTotal = 0;
        let endVNDTotal = 0;
        let endUSDTotal = 0;
        legalData[title].forEach((line) => {
            startVNDTotal += line["VNDStart_ByLegal"];
            startUSDTotal += line["USDStart_ByLegal"];
            midVNDTotal += line["VNDMid_ByLegal"];
            midUSDTotal += line["USDMid_ByLegal"];
            endVNDTotal += line["VNDEnd_ByLegal"];
            endUSDTotal += line["USDEnd_ByLegal"];
        });
        return {
            title,
            left: {
                top: [`${startVNDTotal || 0}`, `${startUSDTotal || 0}`],
                bot: legalData[title].map((detail) => {
                    return {
                        type: detail["Type_ByLegal"],
                        vndAmount: detail["VNDStart_ByLegal"],
                        usdAmount: detail["USDStart_ByLegal"]
                    }
                })
            },
            middle: {
                top: [`${midVNDTotal || 0}`, `${midUSDTotal || 0}`],
                bot: legalData[title].map((detail) => {
                    return {
                        type: detail["Type_ByLegal"],
                        vndAmount: detail["VNDMid_ByLegal"],
                        usdAmount: detail["USDMid_ByLegal"]
                    }
                })
            },
            right: {
                top: [`${endVNDTotal || 0}`, `${endUSDTotal || 0}`],
                bot: legalData[title].map((detail) => {
                    return {
                        type: detail["Type_ByLegal"],
                        vndAmount: detail["VNDEnd_ByLegal"],
                        usdAmount: detail["USDEnd_ByLegal"]
                    }
                })
            }
        }
    });

    //STATE
    const [currentView, setViewClass] = useState('middle');
    const [value, setValue] = useState('middle');
    const [updateChart, setUpdateChart] = useState(false);
    const [activeCurrency, setActiveCurrency] = useState('VND');
    const handleTabChange = (evt, newValue) => {
        setValue(newValue);
        setViewClass(newValue);
        switch (newValue) {
            case "middle":
                setTotalLegal({totalVND: totalData.totalVNDMid, totalUSD: totalData.totalUSDMid});
                break;
            case "left": 
                setTotalLegal({totalVND: totalData.totalVNDStart, totalUSD: totalData.totalUSDStart});
                break;
            case "right":
                setTotalLegal({totalVND: totalData.totalVNDEnd, totalUSD: totalData.totalUSDEnd});
                break;
            default: 
                setTotalLegal({totalVND: 0, totalUSD: 0});
        }
        setChartData(legalCardData.map((unit) => {
            let strCash = unit[newValue].top[activeCurrency === 'VND' ? 0 : 1];
            return Number(strCash) >= 0 ? Number(strCash) : 0
        }))
        setUpdateChart(true);
    }
    const [chartData, setChartData] = useState(legalCardData.map((unit) => {
        let strCash = unit[currentView].top[activeCurrency === 'VND' ? 0 : 1];
        return Number(strCash) >= 0 ? Number(strCash) : 0
    }));

    const handleChangeChartData = (currency) => {
        setChartData(legalCardData.map((unit) => {
            let strCash = unit[currentView].top[currency === 'VND' ? 0 : 1];
            return Number(strCash) >= 0 ? Number(strCash) : 0
        }))
        setActiveCurrency(currency);
        setUpdateChart(true);
    }

    //CHART RELATED STUFF
    const chartLabels = Object.keys(legalData);
    
    const backgroundColor = [
        'rgba(252, 3, 3, 0.7)',
        'rgba(252, 194, 1, 0.7)',
        'rgba(60,179,113, 0.7)',
        'rgba(3, 165, 252, 0.7)',
        'rgba(25,10,99,0.7)',
        'rgb(254, 132, 14,0.7)'
    ];
    let chartConfig = {
        labels: chartLabels,
        datasets: [{
            data: chartData,
            backgroundColor: backgroundColor ,
            hoverBackgroundColor: [
                'rgba(252, 3, 3, 1)',
                'rgba(252, 194, 1, 1)',
                'rgba(60,179,113, 1)',
                'rgba(3, 165, 252, 1)',
                'rgba(25,10,99,1)',
                'rgb(254, 132, 14,1)'
            ]
        }]
    };

    ///Currency Choices
    const currencyList = ['VND', 'Ngoại tệ'].map(currency =>
        <Button
            key={currency}
            color={activeCurrency === currency ? "primary" : "default"}
            onClick={() => handleChangeChartData(currency)}
        >
            {currency}
        </Button>
    );
    

    ///Whole list
    const list = legalCardData.map((item, index) => {
        return (
            <ListItem key={item.title}>
                <CollapseCard
                    children1={
                    <TopCard 
                    currentView={currentView}
                    title={item.title}
                    topDescr={item[currentView].top}
                    color={backgroundColor[index]}
                    />}
                    children2={
                    <BotCard
                    currentView={currentView}
                    item={item}
                    />
                    }
                    collapse={true}
                />             
            </ListItem>
        )
    });


    return (
        <div className='page-container'>
            <PageHeader
                pageHeader='XEM TÀI SẢN THEO PHÁP NHÂN'
                lastUpdatedTime={moment().format("MMMM Do YYYY, h:mm A")}
            />
            <div className='btn-group-container'>
                <ButtonGroup variant='contained' size='small' disableElevation>
                    {currencyList}
                </ButtonGroup>
            </div>
            <div className='chart-legal-doughnut'>
                <Pie
                    data={chartConfig}
                    options={
                        {
                        title: {
                            display: true,
                            text: "Biểu đồ tỷ lệ tài sản theo pháp nhân ",
                            position: 'bottom',
                            fontSize: 16
                        },
                        tooltips: {
                            callbacks: {
                                label: function (tooltipItem, data) {
                                    let dataset = data.datasets[tooltipItem.datasetIndex];
                                    let meta = dataset._meta[Object.keys(dataset._meta)[0]];
                                    let total = meta.total;
                                    let currentValue = dataset.data[tooltipItem.index];
                                    let percentage = parseFloat((currentValue / total * 100).toFixed(1));
                                    return Helper.cashShorten(currentValue) + ' (' + percentage + '%)';
                                },
                                title: function (tooltipItem, data) {
                                    return data.labels[tooltipItem[0].index];
                                }
                            }

                        }
                    }}
                    width={100}
                    height={100}
                    redraw={updateChart}
                />
            </div>

            <div className='total-display'>
                <p>Tổng {value==='middle'? 'phát sinh' : value ==='left'? 'đầu kỳ': 'lũy kế'}</p>
                <NumberDisplay
                    classes='neutral'
                    number={totalLegal.totalVND}
                    currency={`VND`}
                />
            </div>
            <Typography className='page-annotation' variant='caption'>VND*: số tiền VND chuyển đổi từ ngoại tệ</Typography>
            <BalanceOptions
                value={value}
                handleTabChange={handleTabChange}
                tabLabels={['Đầu kỳ', 'Phát sinh', 'Lũy Kế']}
                tabValues={['left', 'middle', 'right']}
            />
            <List>
                {list}
            </List>
        </div>
    );
};

const NumberDisplay = ({ classes, number, currency = '', variant = 'h6' }) => {
    const [showDetailNumer, setShowDetailNumber] = useState(false);
    const handleSwitchNumber = () => {
        setShowDetailNumber(!showDetailNumer);
    }
    let roundedNumber = Math.round(Number(number));
    return (
        <Typography onClick={handleSwitchNumber} className={classes} component="span" variant={variant}>
            {`${showDetailNumer ? Helper.numberWithCommas(roundedNumber) : Helper.cashShorten(roundedNumber)} ${currency}`}
        </Typography>
    )
}

const TopCard = ({ title,currentView,topDescr }) => {
    let topcard = 
    <div className="collapse-card-top">
        <div className="collapse-card-title top-card-title">{title}</div>
        <div
            className={'collapse-card-description top-card-descript '}
        >
            {
                    <>
                        <NumberDisplay
                            classes={`collapse-card-top-desc ${Number(topDescr[0]) < 0 ? 'red' : Number(topDescr[0]) > 0 ? 'green' : ''}`}
                            number={topDescr[0]}
                            currency={'VND'}
                        />
                        <NumberDisplay
                            classes={`collapse-card-top-desc ${Number(topDescr[1]) < 0 ? 'red' : Number(topDescr[1]) > 0 ? 'green' : ''}`}
                            number={topDescr[1]}
                            currency={'VND*'}
                        />
                    </>
                }
        </div>
    </div>
    return (
        <>
            {topcard}
        </>
    )}

const BotCard = ({item,currentView}) => {
    const sortedItem = [...item[currentView].bot].sort(function(a, b){
        return a.type === b.type ? 0 : +(a.type > b.type) || -1;
      });
    
    let botCard = sortedItem.map(detail =>
        <div key={detail.type}>
            <Divider />
            <div className='collapse-card-bottom'>
                <div
                    className='collapse-card-title bot-card-title'>
                    {detail.type}
                </div>
                <div
                    className={'collapse-card-description bot-card-descript '}
                >
                    <NumberDisplay
                        classes={detail.vndAmount < 0 ? 'red' : detail.vndAmount > 0 ? 'green' : ''}
                        number={detail.vndAmount}
                        currency={'VND'}
                        variant='body1'
                    />
                    {
                        detail.usdAmount !== 0 &&
                        <NumberDisplay
                            classes={detail.usdAmount < 0 ? 'red' : detail.usdAmount > 0 ? 'green' : ''}
                            number={detail.usdAmount}
                            currency={'VND*'}
                            variant='body1'
                        />
                    }
                </div>
            </div>
        </div>
    )
    return (
        <>
            {botCard}
        </>
    )
}

export default LegalPage;
